import React from 'react';

import Wrapper from '../components/Wrapper';

function Rising() {
  return (
    <Wrapper>
      <aside>
        <h1>Posts Rising in Popularity</h1>
      </aside>
      <main>
        {
          [].map(() => (
            <></>
          ))
        }
      </main>
    </Wrapper>
  );
}

export default Rising;
