import React from 'react';

import Wrapper from '../components/Wrapper';

function Home() {
  return (
    <Wrapper>
      <aside>
        <h1>Popular Posts on Gatherin.gs</h1>

        <p>
          Find interesting posts about all topics on the internet.
        </p>

        <a href="/create">Create New Gathering</a>
        <a href="/submit">Submit a New Post</a>
      </aside>
      <main>
        {
          [].map(() => (
            <></>
          ))
        }
      </main>
    </Wrapper>
  );
}

export default Home;
