import React, { useState, useEffect, FC, ReactNode } from 'react';

import { auth } from '../utils/firebase';
import { onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, User } from 'firebase/auth';

interface Props {
  children?: ReactNode;
}

interface UserContextInterface {
  firebaseUser: any;
  currentUser: any;
  isLoggedIn: boolean;
  setCurrentUser: (user: any) => void;
}

const initialState: UserContextInterface = {
  firebaseUser: null,
  currentUser: null,
  isLoggedIn: false,
  setCurrentUser: () => {},
};

export function login(email: string, password: string) {
  signInWithEmailAndPassword(auth, email, password)
    .catch((err) => alert(err.message));
}

export function signup(email: string, password: string) {
  createUserWithEmailAndPassword(auth, email, password)
    .catch((err) => alert(err.message));
}

export function logout() {
  signOut(auth)
    .catch((err) => alert(err.message));
}

export async function getCurrentUser (firebaseUser: User) {
  if (!firebaseUser) return;

  return await firebaseUser
    .getIdToken(false /* forceRefresh */)
    .then(async (idToken: string) => {
      return await fetch(`${process.env.REACT_APP_CLOUDFLARE_WORKER_URL}/user`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${idToken}` },
      });
    })
    .then(async (res: Response) => {
      return await res.json();
    })
    .catch(alert);
};

export const UserContext = React.createContext<UserContextInterface>(initialState);

const StateMachine: FC<Props> = ({ children }: Props) => {
  const [firebaseUser, setFirebaseUser] = useState(initialState.firebaseUser);
  const [currentUser, setCurrentUser] = useState(initialState.currentUser);
  const [isLoggedIn, setIsLoggedIn] = useState(initialState.isLoggedIn);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      setFirebaseUser(user);

      if (!user) return setIsLoggedIn(false);
      else setIsLoggedIn(true);

      const currentUser = await getCurrentUser(user);
      console.log('current user', currentUser);
      setCurrentUser(currentUser);
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        firebaseUser,
        currentUser,
        isLoggedIn,
        setCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default StateMachine;
