import React, { MouseEvent, useState } from 'react';

import { UserContext } from '../contexts/UserContext';
import Wrapper from '../components/Wrapper';

function Create() {
  const { currentUser, firebaseUser, isLoggedIn } = React.useContext(UserContext);

  const [identifier, setIdentifier] = useState('');

  function createGathering(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (!isLoggedIn || !currentUser) {
      return; // TODO -- Pop up Login Interface
    }

    firebaseUser
      .getIdToken(false /* forceRefresh */)
      .then(async (idToken: string) => {
        return await fetch(`${process.env.REACT_APP_CLOUDFLARE_WORKER_URL}/gathering`, {
          method: 'PUT',
          headers: { Authorization: `Bearer ${idToken}` },
          body: JSON.stringify({ identifier }),
        })
      })
      .catch(console.error);
  }

  return (
    <Wrapper>
      <aside>
        <h1>Create a Gathering</h1>
      </aside>
      <main>
        <form>
          <div>
            <label htmlFor="gathering-name">Gathering Name</label>
            <br />
            <input type="text" name="identifier" id="gathering-name" placeholder="My Awesome Group" onChange={(e) => setIdentifier(e.target.value)} />
          </div>

          <button type="submit" onClick={createGathering}>Create</button>
        </form>
      </main>
    </Wrapper>
  );
}

export default Create;
