import React, { useState } from 'react';

import { UserContext, login, signup, logout } from '../contexts/UserContext';

function Login() {
  const { currentUser, firebaseUser, isLoggedIn } = React.useContext(UserContext);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function associateUsername() {
    if (!firebaseUser) return;

    firebaseUser
      .getIdToken(false /* forceRefresh */)
      .then(async (idToken: string) => {
        console.log(idToken);

        return await fetch(`${process.env.REACT_APP_CLOUDFLARE_WORKER_URL}/user`, {
          method: 'PUT',
          headers: { Authorization: `Bearer ${idToken}` },
          body: JSON.stringify({ username }),
        });
      }).catch(alert);
  }

  return (
    <div className="signup">
      <form>
        <div>Is User Logged In? {isLoggedIn ? 'Yes' : 'No'}</div>

        <div>
          <label htmlFor="username">Username</label>
          <input onChange={(e) => setUsername(e.target.value)} type="text" id="username" />
        </div>

        <div>
          <label htmlFor="email">Email</label>
          <input onChange={(e) => setEmail(e.target.value)} type="text" id="email" />
        </div>

        <div>
          <label htmlFor="password">Password</label>
          <input onChange={(e) => setPassword(e.target.value)} type="password" id="password" />
        </div>

        <button type="button" onClick={ () => login(email, password) }>
          Log In
        </button>
        <button type="button" onClick={ () => signup(email, password) }>
          Sign Up
        </button>
        <button type="button" onClick={ associateUsername }>
          Set Username
        </button>
        <button type="button" onClick={ () => logout() }>
          Log Out
        </button>
      </form>

      <pre>{JSON.stringify(firebaseUser, null, 2)}</pre>
      <pre>{JSON.stringify(currentUser, null, 2)}</pre>
    </div>
  );
}

export default Login;
