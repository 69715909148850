import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.css';
import 'react-quill/dist/quill.snow.css';

import StateMachine from './contexts/UserContext';

import Footer from './components/Footer';
import Header from './components/Header';
import Login from './components/Login';

import Create from './views/Create';
import Gathering from './views/Gathering';
import Home from './views/Home';
import Post from './views/Post';
import Profile from './views/Profile';
import Submit from './views/Submit';
import User from './views/User';
import Top from './views/Top';
import Rising from './views/Rising';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/for/:identifier',
    element: <Gathering />,
  },
  {
    path: '/for/:identifier/:postUri',
    element: <Post />,
  },
  {
    path: '/from/:username',
    element: <User />,
  },
  {
    path: '/submit',
    element: <Submit />,
  },
  {
    path: '/create',
    element: <Create />,
  },
  {
    path: '/top',
    element: <Top />,
  },
  {
    path: '/rising',
    element: <Rising />,
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <StateMachine>
      <Header />
      <RouterProvider router={router} />
      <Footer />
    </StateMachine>
  </React.StrictMode>
);
