import React, { useState } from 'react';

import { UserContext, logout } from '../contexts/UserContext';

import styles from './Header.module.css';

function Header() {
  const { currentUser, isLoggedIn } = React.useContext(UserContext);
  const [showMobileNav, setShowMobileNav] = useState(false);

  function toggleShowMobileNav() {
    setShowMobileNav(!showMobileNav);
  }

  return (
    <header className={ styles.Header }>
      <div className={ showMobileNav ? styles.showMobileNav : null }>
        <a href="/">
          <img alt="Gatherin.gs Logo" src="/logo.png" />
        </a>

        <span onClick={ toggleShowMobileNav } className={ styles.hamburger }>|||</span>

        <nav>
          <a href="/top">Top</a>
          <a href="/rising">Rising</a>
        </nav>
        <div className={ styles.userSection }>
          {
            isLoggedIn && currentUser && (
              <>
                <a href="/profile">{ currentUser.username }</a>
                <a href="/logout" onClick={ logout }>Logout</a>
              </>
            )
          }
          {
            (!isLoggedIn || !currentUser) && (
              <>
                <a href="/login">Login</a>
                <a href="/register">Register</a>
              </>
            )
          }
        </div>
      </div>
    </header>
  );
}

export default Header;
