import React, { useState } from 'react';

import styles from './Wrapper.module.css';

function Wrapper(props: { children: JSX.Element[] }) {
  const [showAside, setShowAside] = useState(false);

  function toggleAside() {
    setShowAside(!showAside);
  }

  return (
    <div className={ styles.Wrapper + ' ' + ( showAside ? styles.showAside : null ) }>
      <div onClick={ toggleAside } className={ styles.mobileAside }>
        {showAside ? 'Less Info' : 'More Info' }
      </div>
      { props.children }
    </div>
  );
}

export default Wrapper;
