import React from 'react';

import styles from './Footer.module.css';

function Footer() {
  return (
    <footer className={ styles.Footer }>
      <div>
        <img alt="Gatherin.gs Logo" src="logo.png" />
      </div>
    </footer>
  );
}

export default Footer;
