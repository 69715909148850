import React from 'react';

import Wrapper from '../components/Wrapper';

function User() {
  return (
    <Wrapper>
      <aside>
        <h1>User</h1>
      </aside>
      <main>
        User's Posts and Comments
      </main>
    </Wrapper>
  );
}

export default User;
