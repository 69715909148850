import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyAzZnVhZymAtsPIw2MOLIgx9Ds12HU-CFo',
  authDomain: 'gatherings-93cc5.firebaseapp.com',
  projectId: 'gatherings-93cc5',
  storageBucket: 'gatherings-93cc5.appspot.com',
  messagingSenderId: '1033280278444',
  appId: '1:1033280278444:web:eed100b7d439ae09217409'
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
