import React from 'react';

import Wrapper from '../components/Wrapper';

function Profile() {
  return (
    <Wrapper>
      <aside>
        <h1>Profile</h1>
      </aside>
      <main>
        Profile
      </main>
    </Wrapper>
  );
}

export default Profile;
