import React, { MouseEvent, useState } from 'react';
import Quill from 'react-quill';

import { UserContext } from '../contexts/UserContext';

import Wrapper from '../components/Wrapper';

function Submit() {
  const { currentUser, firebaseUser, isLoggedIn } = React.useContext(UserContext);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [gathering, setGathering] = useState('');

  function submitPost(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (!isLoggedIn || !currentUser) {
      return; // TODO -- Pop up Login Interface
    }

    firebaseUser
      .getIdToken(false /* forceRefresh */)
      .then(async (idToken: string) => {
        return await fetch(`${process.env.REACT_APP_CLOUDFLARE_WORKER_URL}/gathering/post`, {
          method: 'PUT',
          headers: { Authorization: `Bearer ${idToken}` },
          body: JSON.stringify({ title, content, gathering }),
        })
      })
      .catch(console.error);
  }

  return (
    <Wrapper>
      <aside>
        <h1>Submit a Post</h1>
      </aside>
      <main>
        <form>
          <div>
            <label htmlFor="post-title">Post Title</label>
            <input type="text" name="title" id="post-title" placeholder="My Post Title" onChange={(e) => setTitle(e.target.value)} />
          </div>

          <div>
            <label htmlFor="post-gathering">Gathering</label>
            <input type="text" name="gathering" id="post-gathering" onChange={ (e) => setGathering(e.target.value) } />
          </div>

          <Quill theme="snow" value={ content } onChange={ setContent } />
          <input type="hidden" name="content" value={ content } />

          <button type="submit" onClick={ submitPost }>Submit</button>
        </form>
      </main>
    </Wrapper>
  );
}

export default Submit;
